import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Box } from 'atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getProposalIssues } from 'api/proposals'
import ProposalIssuesTable from '../table/issues'
import IssueDetails from './issues/details'

type ChildProps = {
  selected: any
  setHideFooterActions: Dispatch<SetStateAction<boolean>>
}

const DrawerIssues: FC<ChildProps> = ({ selected, setHideFooterActions }) => {
  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )
  const [loading, setLoading] = useState(true)

  const [issues, setIssues] = useState([])
  const [showIssueDetails, setShowIssueDetails] = useState(false)
  const [selectedIssue, setSelectedIssue] = useState(null)

  const fetchProductIssues = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalIssues({
      proposalId: proposalDetails.id,
    })
    setIssues(data.results)
    setLoading(false)
  }

  useEffect(() => {
    fetchProductIssues(false)
  }, [])

  return (
    <Box dataTestid='issues-tab-content' padding={{ top: 4, bottom: 4 }}>
      {!selectedIssue && !showIssueDetails ? (
        <ProposalIssuesTable
          data={issues}
          loading={loading}
          isProductDrawer
          onRowClick={(issue) => {
            setSelectedIssue(issue)
            setShowIssueDetails(true)
            setHideFooterActions(true)
          }}
        />
      ) : (
        <IssueDetails
          selected={selectedIssue}
          handleCloseDrawer={() => {
            setShowIssueDetails(false)
            setSelectedIssue(null)
            setHideFooterActions(false)
          }}
          loadIssues={fetchProductIssues}
          isProductDrawer
        />
      )}
    </Box>
  )
}

export default DrawerIssues
